





















































import { Component, Vue, Prop } from 'vue-property-decorator';
import { INavigatorItem } from '@/model/util/INavigatorItem';
import LoginMixin from '@/mixing/MixinLogin.vue';
import Util from '@/utils/Util';
import { Icon } from '@/model/util/Icon';
import { IPermission } from '@/model/login/IPermission';

@Component({
	name: 'Navegator',
})
export default class Navegator extends LoginMixin {
	public selected = 0;
	public title = this.$t("AppNavigatorText.title");
	public option = 1;
	protected circleOutline =
		'mdi-checkbox-blank-circle-outline';
	protected circleBlank = 'mdi-checkbox-blank-circle ';
	private currentRoute = '';

	@Prop() readonly keycloak!: string

	public items: Array<INavigatorItem> = [
		{
			title: this.$t("AppNavigatorText.inicio") as string,
			urlName: 'Menu',
			parent: 'Inicio',
			module: 'view_inicio',
			subMenus: [],
		},
		{
			title: this.$t("AppNavigatorText.dashboard") as string,
			urlName: 'Dashboards',
			parent: 'dashboard',
			module: 'view_inicio_dashboard',
			subMenus: [
			{
				title: this.$t("AppNavigatorText.habilidades") as string,
				urlName: 'MainHabilidadesBlandas',
				parent: 'habilidades',
				module: 'view_habilidades_blandas'
				}
			],
		},
		{
			title: this.$t("AppNavigatorText.dashAgent") as string,
			urlName: 'MainDashAgentes',
			parent: 'dashAgentes',
			module: 'view_dashAgentes',
			subMenus: [
			// {
			// 		title: this.$t("AppNavigatorText.listAgent") as string,
			// 		urlName: 'MainListAgents',
			// 		parent: 'listAgents',
			// 		module: 'view_list_agents'
			// 	}
			],
		},
		{
			title: this.$t("AppNavigatorText.administracion") as string,
			urlName: 'MainAdministration',
			parent: 'administracion',
			module: 'view_administracion',
			subMenus: [],
		},
		{
			title: "Búsqueda de audios",
			urlName: 'MainTranscription',
			parent: 'dashboard',
			module: 'view_audioSearch',
			subMenus: [],
		},
		{
			title: this.$t("AppNavigatorText.biblioteca") as string,
			urlName: 'MainAudioSearch', //'MainAudioLibrary',
			parent: 'audioSearch', //'library',
			module: 'view_audioSearch', //'view_library',
			subMenus: [
				{
					title: this.$t("AppNavigatorText.cargar") as string,
					urlName: 'MainAudioLibrary', //'MainAudioSearch',
					parent: 'library', //'audioSearch',
					module: 'view_library',//'view_audioSearch',
				}
			],
		},
		{
			title: this.$t("AppNavigatorText.reportes") as string,
			urlName: 'MainReports',
			parent: 'reportes',
			module: 'view_reports',
			subMenus: [
				{
					title: this.$t("AppNavigatorText.reportsTags") as string,
					urlName: 'MainReportsTags',
					parent: 'reportsTag',
					module: 'view_reports_tags'
				},
				{
					title: this.$t("AppNavigatorText.reportsPonder") as string,
					urlName: 'MainRepPonderadores',
					parent: 'ponderadores',
					module: 'view_rep_ponderadores'
				},
				{
					title: this.$t("AppNavigatorText.reportsMotiv") as string,
					urlName: 'MainReportsMotMora',
					parent: 'repMotivoMora',
					module: 'view_rep_motivoMora'
				},
			]
		},
		{
			/*Este titulo se utiliza cuando se muestran estas vistas en tab <MainTagAudios>*/
			title: this.$t("AppNavigatorText.tagsAudio") as string,
			urlName: 'MainTagAudios',
			parent: 'configTagsAudios',
			module: 'view_configTagsAudios',
			/*title: this.$t("AppNavigatorText.motivos") as string,
			urlName: 'MainMotivos',
			parent: 'motivo',
			module: 'view_motivos',*/
			subMenus: [
				{
					title: this.$t("AppNavigatorText.motivos2") as string,
					urlName: 'MainTags',
					parent: 'audiotag',
					module: 'view_tags',
				},
				/*{
					title: this.$t("AppNavigatorText.motivos") as string,
					urlName: 'MainMotivos',
					parent: 'motivo',
					module: 'view_motivos',
				},*/
			]
		},/*
		{
			title: this.$t("AppNavigatorText.transcripcion") as string,
			urlName: 'MainAdministration',
			parent: 'administracion',
			module: 'view_administracion',
			subMenus: [],

		},
		{
			title: this.$t("AppNavigatorText.evaluacion") as string,
			urlName: 'MainEvaluation',
			parent: 'evaluacion',
			module: 'view_gestion_transcripciones',
			subMenus: [],
		},*/
		{
			title: this.$t("AppNavigatorText.usuarios") as string,
			urlName: 'MainUser',
			parent: 'usuario',
			module: 'view_gestion_usuario',
			subMenus: [],
		},
		{
			title: this.$t("AppNavigatorText.rankings") as string,
			urlName: '',
			parent: 'usuario',
			module: 'view_gestion_usuario',
			subMenus: [],
		},
		{
			title: this.$t("AppNavigatorText.script") as string,
			//urlName: 'MainScripts',
			urlName: 'MainScriptsTable',
			parent: 'scriptDataTable',
			// module: 'view_table_script',
			module: 'view_gestion_usuario',
			subMenus: [
				{
					title: this.$t("AppNavigatorText.scriptCreate") as string,
					urlName: 'MainScripts',
					parent: 'script',
					// module: 'view_gestion_script',
					module: 'view_gestion_usuario',
				},
			]	
		},
	];
	mounted() {
		this.currentRoute = this.$route.meta!.parent;
		//console.log("Ruta", this.currentRoute);
	}

	public changeRoute(to: INavigatorItem) {
		console.log(to);
		if (to.title === 'Salir') {
			//this.logout();
			//Util.showMessage('Hasta pronto', Icon.INFO);
			this.$router.push({
				name: 'Login',
			});
			//this.keycloak.logout();
			return;
		}
		if (
			to.urlName !== this.currentRoute &&
			to.urlName !== undefined
		) {
			console.log("router.push");
			this.currentRoute = to.parent;
			this.$router.push({
				name: to.urlName,
			});
		}
	}
	public ComprobarVistas(recurso: string){
		
		if(this.getPermission){
			// console.log("permisos: ", this.getPermission);
			
			let permiso = this.getPermission.some((permission: IPermission) => permission.rsname == recurso);
			//console.log("permiso:", permiso)
			return permiso
		}
	}
}
